import './index.scss'
import Masonry from 'react-masonry-css'
import React from 'react'
import Swiper from 'react-id-swiper'
import { CommentsCard } from '../../molecules/CommentsCard'
import { Title } from '../../atoms/Title'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const breakpointColumnsObj = {
  default: 3,
  1023: 2
}

const paramsComments = {
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  preventClicks: false,
  preventClicksPropagation: false
}

export const CommentsSection = ({ page, commentsCard }) => {
  const wWidth = useSelector(selectDeviceSize)

  return (
    <section
      className={`comments ${page === 'Psychotherapist' ? `in${page}` : ''}`}
    >
      <div className="container">
        <div className="row">
          <div className="designer-col col-12">
            <p className="mainTitle">
              <Title.PasH2Styles>
                Вот что пишут о YouTalk наши клиенты
              </Title.PasH2Styles>
            </p>

            {wWidth > 767 ? (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="comments__cards comments-cards"
                columnClassName="comments-cards__col"
              >
                {commentsCard.map(
                  ({ img, imgAlt, name, age, post, title, desc }, i) => (
                    <div key={i} className="comments-cards__card comments-card">
                      <CommentsCard
                        age={age}
                        desc={desc}
                        img={img}
                        imgAlt={imgAlt}
                        name={name}
                        post={post}
                        title={title}
                      />
                    </div>
                  )
                )}
              </Masonry>
            ) : (
              <div className="comments__cards comments-cards">
                <Swiper {...paramsComments}>
                  {commentsCard.map(
                    ({ img, name, age, post, title, desc }, i) => (
                      <div key={i} className="comments-cards__card">
                        <div className="comments-card">
                          <div className="comments-card__head comments-card-head">
                            <img
                              alt=""
                              className="comments-card-head__ava"
                              src={`../img/comments/${img}`}
                            />
                            <div className="comments-card-head__info">
                              <p className="comments-card-head__name">{name}</p>
                              <p className="comments-card-head__age">{age}</p>
                              <p className="comments-card-head__post">{post}</p>
                            </div>
                          </div>
                          <div className="comments-card__content comments-content">
                            <h5 className="comments-content__title">{title}</h5>
                            <p className="comments-content__desc">{desc}</p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Swiper>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
