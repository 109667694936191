const descriptions = [
  'Портрет девушки с розовыми волосами на зеленом фоне',
  'Портрет девушки в очках с черными волосами на красном фоне',
  'Портрет мужчины в очках со светлыми кудрявыми волосами на синем фоне',
  'Портрет лысого мужчины с телефоном на зеленом фоне',
  'Портрет усатого мужчины с черными волосами на красном фоне',
  'Портрет бородатого кудрявого мужчины с черными волосами на светло-оранжевом фоне',
  'Портрет женщины со светлыми волосами на синем фоне',
  'Портрет темноволосой девушки на желтом фоне',
  'Портрет темноволосой девушки с длинными волосами на зеленом фоне',
  'Портрет улыбающейся женщины с белыми волосами на красном фоне',
  'Портрет темноволосового кудрявого мужчины с усами и в очках на светло-оранжевом фоне',
  'Портрер мужчины с короткими белыми волосами на зеленом фоне',
  'Портрет рыжеволосого молодого человека на синем фоне',
  'Портрет мужчины с короткими темными волосами на желтом фоне'
]

const defaultAlt = 'Аватар человека оставившего отзыв'

export const getImgAltForComments = (avatarName) => {
  const match = avatarName.match(/^person(\d+)\.svg$/)
  if (match) {
    const index = Number.parseInt(match[1], 10)
    if (index >= 0 && index < descriptions.length) {
      return descriptions[index]
    } else {
      return defaultAlt
    }
  } else {
    return defaultAlt
  }
}
