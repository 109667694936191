export const travmaCommentsData = [
  {
    desc: 'Я искал психолога для работы с потерей близкого человека. Сервис Youtalk предложил мне несколько специалистов, специализирующихся на горевании и утрате. Я оценил подробные описания подходов и опыта работы в этой области. После нескольких сеансов с выбранным психологом я почувствовал значительное облегчение.',
    name: 'Иван',
    age: '21 год',
    title: 'Я почувствовал значительное облегчение',
    img: 'person13.svg'
  },
  {
    desc: 'Месяц назад начала заниматься с психологом YouTalk. И я так довольна! Чувствую, что наконец нашла человека, который понимает меня. После каждой сессии ощущаю облегчение. Жалею, что не обратилась раньше!',
    name: 'Алина',
    age: '38 лет',
    title: 'Наконец нашла человека, который понимает меня',
    img: 'person7.svg'
  },
  {
    desc: 'Я искала специалиста с возможностью удаленной консультации и нашла сервис Youtalk. Сама выбрала психолога и не пожалела! С первой встречи у нас с психологом сложился контакт, психолог помогла разобраться в моих мыслях. Составили план по моему запросу, ничего не навязывают. Идем в комфортном для меня темпе. Я очень довольна своим специалистом и сервисом!',
    name: 'Амина',
    age: '40 лет',
    title: 'Я очень довольна своим специалистом и сервисом',
    img: 'person8.svg'
  },
  {
    desc: 'В Юток большой выбор специалистов, которые работают с разными направлениями психотерапии. При подборе немного потерялась, так много всего. Поэтому обратилась за помощью в клиентскую службу. Консультант мне помогла разобраться в подходах и направлениях. Вместе определились, с кем мне будет работать эффективнее. Мне было важно, чтобы человек был опытный, имел высшее образование. В Ютоке у всех психологов есть высшее профильное образование и опыт работы. Видно, что сервис тщательно подходит к выбору своих специалистов.',
    name: 'Алина',
    age: '40 лет',
    title: 'Консультант мне помогла разобраться в подходах и направлениях',
    img: 'person1.svg'
  },
  {
    desc: 'Пришла по рекомендации подруги. Спустя время я чувствую, что моя жизнь меняется к лучшему благодаря поддержке моего психолога. Единственное, к сожалению, у моего психолога не всегда бывают свободные окошки по выходным.',
    name: 'Светлана',
    age: '34 года',
    title: 'Жизнь меняется к лучшему благодаря поддержке моего психолога',
    img: 'person0.svg'
  },
  {
    desc: 'Доступная цена и широкий выбор специалистов - это основные плюсы сервиса. Я смогла найти психолога, который подходит мне по стоимости и по методу работы 🙏🏻',
    name: 'Елизавета',
    age: '18 лет',
    title:
      'Я смогла найти психолога, который подходит мне по стоимости и по методу работы',
    img: 'person6.svg'
  }
]
