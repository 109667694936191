import React from 'react'
import {
  BlockImage,
  ButtonWrapper,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './WhatToWorkTravma.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const WhatToWorkTravma = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <BlockImage alt="С чем предстоит работать психологу при лечении травмы" />
          </ImageWrapper>
          <ButtonWrapper>
            <Button.NewPrimary href="/wizard/" size="large" type="link">
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                С чем предстоит работать психологу при лечении травмы?
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Важными аспектами лечения психотравмы становится работа с
                переживаниями из-за произошедшего события и симптомами,
                появившимися впоследствии. В терапии, с одной стороны, вы будете
                обсуждать само произошедшее событие, ваши чувства относительно
                него, а с другой стороны — то, какие проблемы проявляются в
                жизни прямо сейчас: сниженное настроение, тревогу,
                нерациональные страхи, бессонницу и навязчивые мысли.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
